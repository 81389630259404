import { post } from "./axios";

//通用模块
//员工登录
export const login = (params) => {
    return post('/oa/user/login', params)
}

//退出登录
export const logout = (params) => {
    return post('/oa/user/logout', params)
}

//修改员工登录密码
export const change_passwor = (params) => {
    return post('/oa/user/change_passwor', params)
}

//自己修改登录密码
export const change_password_byself = (params) => {
    return post('/oa/user/change_password_byself', params)
}

//部门列表
export const get_department_list = (params) => {
    return post('/oa/user/get_department_list', params)
}

//添加部门信息
export const add_department = (params) => {
    return post('/oa/user/add_department', params)
}

//编辑部门信息
export const edit_department = (params) => {
    return post('/oa/user/edit_department', params)
}

//删除部门信息
export const del_department = (params) => {
    return post('/oa/user/del_department', params)
}

//获取员工列表
export const get_employee_list = (params) => {
    return post('/oa/user/get_employee_list', params)
}

//添加员工
export const add_employee = (params) => {
    return post('/oa/user/add_employee', params)
}

//修改员工信息
export const edit_employee = (params) => {
    return post('/oa/user/edit_employee', params)
}

//获取员工详细信息
export const get_employee_detail = (params) => {
    return post('/oa/user/get_employee_detail', params)
}

//删除员工信息
export const del_employee = (params) => {
    return post('/oa/user/del_employee', params)
}

//获取企业微信部门
export const get_workchat_department = (params) => {
    return post('/oa/account/get_workchat_department', params)
}

//获取企业微信员工
export const get_workchat_employee = (params) => {
    return post('/oa/account/get_workchat_employee', params)
}

//获取钉钉部门
export const get_dingtalk_department = (params) => {
    return post('/oa/account/get_dingtalk_department', params)
}

//获取钉钉员工信息
export const get_dingtalk_employee = (params) => {
    return post('/oa/account/get_dingtalk_employee', params)
}

//获取大数据账号信息
export const get_bigdata_user = (params) => {
    return post('/oa/account/get_bigdata_user', params)
}

//获取EC账号信息
export const get_ec_user = (params) => {
    return post('/oa/account/get_ec_user', params)
}

//获取校管家账号信息
export const get_xiaoguanjia_user = (params) => {
    return post('/oa/account/get_xiaoguanjia_user', params)
}

//获取教学系统账号信息
export const get_jiaoxue_user = (params) => {
    return post('/oa/account/get_jiaoxue_user', params)
}

//获取中小学家长社区用户
export const get_zhongxiaoxue_user = (params) => {
    return post('/oa/account/get_zhongxiaoxue_user', params)
}

//绑定第三方账号
export const bind_account = (params) => {
    return post('/oa/account/bind_account', params)
}

//取消绑定第三方账号
export const unbind_account = (params) => {
    return post('/oa/account/unbind_account', params)
}

//批量同步账号
export const sync_account = (params) => {
    return post('/oa/account/sync_account', params)
}

//获取所有公司列表
export const get_company_list = (params) => {
    return post('/oa/user/get_company_list', params)
}

//获取所有公众号列表
export const get_mp_list = (params) => {
    return post('/oa/weixin/get_mp_list', params)
}

//获取所有关注用户列表
export const get_union_user_list = (params) => {
    return post('/oa/weixin/get_union_user_list', params)
}

//设置员工的微信
export const set_employee_unionid = (params) => {
    return post('/oa/weixin/set_employee_unionid', params)
}

//获取已经设置的员工微信
export const get_employee_unionid_list = (params) => {
    return post('/oa/weixin/get_employee_unionid_list', params)
}

//删除已经设置的员工对应微信
export const del_employee_unionid = (params) => {
    return post('/oa/weixin/del_employee_unionid', params)
}

//获取我名下微信粉丝列表
export const my_user_list = (params) => {
    return post('/oa/weixin/my_user_list', params)
}

//领取或者分配给某个员工粉丝
export const get_my_user = (params) => {
    return post('/oa/weixin/get_my_user', params)
}

//领取或者分配给某个员工粉丝
export const del_my_user = (params) => {
    return post('/oa/weixin/del_my_user', params)
}

// 获取微信粉丝详细信息
export const get_union_user_detail = (params) => {
    return post('/oa/weixin/get_union_user_detail', params)
}

// 公众号粉丝编辑
export const edit_union_user = (params) => {
    return post('/oa/weixin/edit_union_user', params)
}