import React, { } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Index from '../pages/index'
import Login from '../pages/login'

const router = (props) => {
  return (
    <Router basename="/">
      <div className="App">
        <main>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/" component={Index} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default router;