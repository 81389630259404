import React from 'react';
import { Tabs } from 'antd';
import DepartmentManagement from './personnel/department_management'
import StaffManagement from './personnel/staff_management'
const { TabPane } = Tabs;

const Personnel_management = () => {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="部门管理" key="1">
          <DepartmentManagement />
        </TabPane>
        <TabPane tab="员工管理" key="2">
          <StaffManagement />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Personnel_management;