import React, { useEffect, useState } from 'react';
import { Tree, Table, Input, Select, Button, message, Modal, Form, Popconfirm } from 'antd';
import "../css/main_list.css";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from '@ant-design/icons';
import {
  get_department_list,
  get_employee_list,
  sync_account,
  get_bigdata_user,
  get_jiaoxue_user,
  get_ec_user,
  get_xiaoguanjia_user,
  bind_account,
  unbind_account,
  get_dingtalk_department,
  get_dingtalk_employee,
  get_workchat_department,
  get_workchat_employee,
  get_zhongxiaoxue_user
} from "../server/https";
import { changeTreeDate } from "../common/changeTreeDate";

const { Option, OptGroup } = Select;
const params_mode = {
  name: '',
  username: '',
  permission: '',
  state: '',
  personal_mobile: '',
  department_id: '',
  is_del: ''
}
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 12,
  },
}

const Mail_list = (props) => {
  const [gData, gDataSet] = useState([])
  const [params, paramsSet] = useState(params_mode)
  const [employee_list, employee_listSet] = useState([])
  const [page, pageSet] = useState(1)
  const [total, totalSet] = useState()
  const [info, infoSet] = useState()
  const [visible, visibleSet] = useState(false)
  const [height, heightSet] = useState()
  const [employee_id, employee_idSet] = useState()
  const [type, typeSet] = useState()
  const [username, usernameSet] = useState('')
  const [name, nameSet] = useState('')
  const [user_list, user_listSet] = useState([])
  const [department_list_form, department_list_formSet] = useState([])
  const [title, titleSet] = useState('')
  const [form] = Form.useForm();

  useEffect(() => {
    usernameSet('')
    nameSet('')
    get_department_listFun()
    handleSize()
    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  const handleSize = () => {
    let height = document.body.clientHeight
    heightSet(height)
  }

  const get_department_listFun = e => {
    get_department_list().then(res => {
      if (res.code === 0) {
        let newlist = changeTreeDate(res.data.list, 'title', 'name')
        newlist = changeTreeDate(newlist, 'key', 'id')
        gDataSet(newlist)
      }
    })
  }

  const tableChange = (pagination) => {
    pageSet(pagination.current)
  }

  const onDragEnter = info => {
    let data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data['department_id'] = info
    paramsSet(data)
    infoSet(info)
  }

  useEffect(() => {
    get_employee_listFun()
  }, [info])// eslint-disable-line react-hooks/exhaustive-deps

  const get_employee_listFun = e => {
    let data = params
    get_employee_list(data).then(res => {
      if (res.code === 0) {
        let newlist = changeTreeDate(res.data.list, 'key', 'id')
        employee_listSet(newlist)
        totalSet(res.data.total_count)
      }
    })
  }

  const onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...gData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    gDataSet(data)
  };

  const confirmtext = '是否确认解绑？'

  const toDetail = id => {
    console.log(id)
  }

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ color: '#18f', cursor: 'pointer' }} onClick={e => { toDetail(record.key) }}>{text}</div>
      )
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '职位',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: '部门',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: '个人手机号',
      dataIndex: 'personal_mobile',
      key: 'personal_mobile',
    },
    {
      title: '大数据',
      dataIndex: 'is_has_bigdata',
      key: 'is_has_bigdata',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(1)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 1)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    },
    {
      title: '教学',
      dataIndex: 'is_has_jiaoxue',
      key: 'is_has_jiaoxue',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(2)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 2)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    },
    {
      title: '钉钉',
      dataIndex: 'is_has_dingtalk',
      key: 'is_has_dingtalk',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(3)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 3)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    },
    {
      title: 'EC',
      dataIndex: 'is_has_ec',
      key: 'is_has_ec',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(4)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 4)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    },
    {
      title: '校管家',
      dataIndex: 'is_has_xiaoguanjia',
      key: 'is_has_xiaoguanjia',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(5)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 5)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    },
    {
      title: '微信',
      dataIndex: 'is_has_workchat',
      key: 'is_has_workchat',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(6)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 6)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    },
    {
      title: '家长社区',
      dataIndex: 'is_has_zhongxiaoxue',
      key: 'is_has_zhongxiaoxue',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '-1' ?
              <CloseCircleTwoTone
                style={{ fontSize: '16px' }}
                onClick={e => {
                  visibleSet(true);
                  employee_idSet(record.key);
                  typeSet(7)
                }}
                twoToneColor="#eb2f96"
              />
              :
              <Popconfirm placement="top" title={confirmtext} onConfirm={e => unbind_accountFun(record.key, 7)} okText="是" cancelText="否">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Popconfirm>
          }
        </div>
      )
    }
  ]

  useEffect(() => {
    switch (type) {
      case 1:
        get_bigdata_userFun();
        titleSet('大数据账户同步');
        break;
      case 2:
        get_jiaoxue_userFun();
        titleSet('教学系统账户同步');
        break;
      case 3:
        get_dingtalk_departmentFun();
        titleSet('钉钉账户同步');
        break;
      case 4:
        get_ec_userFun();
        titleSet('EC账户同步');
        break;
      case 5:
        get_xiaoguanjia_userFun();
        titleSet('校管家账户同步');
        break;
      case 6:
        get_workchat_departmentFun();
        titleSet('企业微信账户同步');
        break;
      case 7:
        get_zhongxiaoxue_userFun();
        titleSet('家长社区账户同步');
        break;
      default: ;
    }
  }, [type])// eslint-disable-line react-hooks/exhaustive-deps

  const get_bigdata_userFun = e => {
    let data = {
      username: username,
      name: name
    }
    get_bigdata_user(data).then(res => {
      if (res.code === 0) {
        user_listSet(res.data.list)
      }
    })
  }

  const get_jiaoxue_userFun = e => {
    let data = {
      username: username,
      name: name
    }
    get_jiaoxue_user(data).then(res => {
      if (res.code === 0) {
        user_listSet(res.data.list)
      }
    })
  }

  const get_ec_userFun = e => {
    let data = {
      username: username,
      name: name
    }
    get_ec_user(data).then(res => {
      if (res.code === 0) {
        let list = changeTreeDate(res.data.list, 'id', 'account')
        list = changeTreeDate(list, 'name', 'userName')
        user_listSet(list)
      }
    })
  }

  const get_xiaoguanjia_userFun = e => {
    let data = {
      username: username,
      name: name
    }
    get_xiaoguanjia_user(data).then(res => {
      if (res.code === 0) {
        user_listSet(res.data.list)
      }
    })
  }

  const get_dingtalk_departmentFun = e => {
    get_dingtalk_department().then(res => {
      if (res.code === 0) {
        department_list_formSet(res.data.list)
        user_listSet([])
      }
    })
  }

  const get_workchat_departmentFun = e => {
    get_workchat_department().then(res => {
      if (res.code === 0) {
        department_list_formSet(res.data.list)
        user_listSet([])
      }
    })
  }

  const get_zhongxiaoxue_userFun = e => {
    get_zhongxiaoxue_user().then(res => {
      if (res.code === 0) {
        let list = changeTreeDate(res.data.list, 'username', 'user_id')
        list = changeTreeDate(list, 'name', 'meta_value')
        user_listSet(list)
      }
    })
  }

  const unbind_accountFun = (id, type) => {
    let data = {
      employee_id: id,
      type: type
    }
    unbind_account(data).then(res => {
      if (res.code === 0) {
        message.success('取消绑定成功！')
        get_employee_listFun()
      } else {
        message.error(res.message)
      }
    })
  }

  const inputChange = (e, id) => {
    let value = typeof (e) === 'undefined' ? '' : typeof (e) === 'object' ? e.target.value : e,
      data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data[id] = value
    paramsSet(data)
  }

  const sync_accountFun = e => {
    sync_account({ employee_id: '' }).then(res => {
      if (res.code === 0) {
        message.success('同步成功！')
        get_employee_listFun()
      } else {
        message.success('同步失败！')
      }
    })
  }

  const handleOk = e => {
    form.validateFields().then((values) => {
      let data = values
      data.employee_id = employee_id
      data.type = type
      bind_account(data).then(res => {
        if (res.code === 0) {
          message.success('提交成功！')
          get_employee_listFun()
          visibleSet(false)
          form.resetFields();
        } else {
          message.warn(res.message)
        }
      })
    }).catch((err) => {
      message.warn('提交失败，请检查表格！')
    });
  }

  const getformlist = e => {
    let data = {
      department: e
    }
    if (type === 3) {
      get_dingtalk_employee(data).then(res => {
        if (res.code === 0) {
          user_listSet(res.data.list)
        }
      })
    } else {
      get_workchat_employee(data).then(res => {
        if (res.code === 0) {
          user_listSet(res.data.list)
        }
      })
    }
  }

  return (
    <div>
      <div className='flex_box'>
        <div className='left_box' style={{ maxHeight: height - 160 + 'px' }}>
          <Tree
            className="draggable-tree"
            blockNode
            onSelect={e => onDragEnter(e)}
            onDrop={e => onDrop()}
            showLine={true}
            treeData={gData}
          />
        </div>
        <div className='right_box' style={{ maxHeight: height - 160 + 'px' }}>
          <div className='search_box'>
            姓名：<Input placeholder="请输入" onChange={e => inputChange(e, 'name')} allowClear />
            用户名：<Input placeholder="请输入" onChange={e => inputChange(e, 'username')} allowClear />
            权限：<Select placeholder="请选择" onChange={e => inputChange(e, 'permission')} allowClear>
              <Option value="1">系统管理员</Option>
              <Option value="2">管理员</Option>
              <Option value="3">校长</Option>
              <Option value="4">主管</Option>
              <Option value="5">普通员工</Option>
            </Select>
            状态：<Select placeholder="请选择" onChange={e => inputChange(e, 'state')} allowClear>
              <Option value="1">正常</Option>
              <Option value="2">冻结</Option>
            </Select>
            个人手机号：<Input placeholder="请输入" onChange={e => inputChange(e, 'personal_mobile')} allowClear />
            {/* 是否删除：<Select placeholder="请选择" onChange={e => inputChange(e, 'is_del')} allowClear>
              <Option value="-1">否</Option>
              <Option value="1">是</Option>
            </Select> */}
            <Button onClick={e => { pageSet(1); get_employee_listFun(); }} style={{ marginRight: '10px' }}>搜索</Button>
            <Button onClick={e => sync_accountFun()} style={{ marginRight: '10px' }}>一键同步</Button>
          </div>
          <Table
            dataSource={employee_list}
            columns={columns}
            pagination={{
              current: page,
              total: total,
              pageSize: 10
            }}
            onChange={e => tableChange(e)}
          />
        </div>
      </div>
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={() => { visibleSet(false); form.resetFields(); }}
        width={800}
      >
        <Form
          form={form}
          {...layout}
          name="添加员工"
        >
          {
            [1, 2, 4, 5, 7].indexOf(type) >= 0 ?
              <Form.Item
                label="用户账号"
                name="username"
                rules={[
                  {
                    required: true,
                    message: '请输入',
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="请选择"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    user_list.map(item => (
                      <Option key={item.username} value={item.username}>{item.name + '-' + item.username}</Option>
                    ))
                  }
                </Select>
              </Form.Item>
              :
              <>

                <Form.Item
                  label="部门"
                  name="department_list_form_id"
                  rules={[
                    {
                      required: true,
                      message: '请输入',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="请选择"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={e => getformlist(e)}
                  >
                    {
                      department_list_form.map(item => (
                        item.children ?
                          <OptGroup key={item.dept_id} label={item.name}>
                            {
                              item.children.map(item => (
                                <Option key={item.dept_id} value={item.dept_id}>{item.name}</Option>
                              ))
                            }
                          </OptGroup>
                          :
                          <Option key={item.dept_id} value={item.dept_id}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  label="用户账号"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: '请输入',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="请选择"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      user_list.map(item => (
                        <Option key={item.userid} value={item.userid}>{item.name + '-' + item.mobile}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>

              </>
          }

        </Form>
      </Modal>
    </div>
  )
}

export default Mail_list;