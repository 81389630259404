import './App.css';
import RouterIndex from './router/router'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd';

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <RouterIndex />
    </ConfigProvider>
  );
}

export default App;
