import { Row, Col, Divider, Popover } from 'antd';
import React, { useState, useEffect } from 'react';
import { get_employee_list } from "../server/https";
import bigdata from '../static/img/bigdata.png'
import dd from '../static/img/dd.png'
import ddbb from '../static/img/ddbb.png'
import ec from '../static/img/ec.png'
import jx from '../static/img/jx.png'
import jzsq from '../static/img/jzsq.png'
import lds from '../static/img/lds.png'
import qw from '../static/img/qw.png'
import rd from '../static/img/rd.png'
import sdb from '../static/img/sdb.png'
import xet from '../static/img/xet.png'
import xgj from '../static/img/xgj.png'
import ydsx from '../static/img/ydsx.png'
import yn from '../static/img/yn.png'
import yp from '../static/img/yp.png'
import yt from '../static/img/yt.png'
import ddbb_code from '../static/img/ddbb-code.jpg'

const list_data1 = [
  {
    img: bigdata,
    name: '大数据营销平台',
    url: 'http://bigdata.yidianedu.com/'
  },
  {
    img: jx,
    name: 'REP教学系统',
    url: 'http://jiaoxue.yanuojiaoyu.com/'
  },
  {
    img: yp,
    name: '一点七云存储',
    url: 'http://yunpan.yidianedu.com/'
  },
  {
    img: yn,
    name: '亚诺官网',
    url: 'https://www.yanuojiaoyu.com/'
  },
  {
    img: rd,
    name: '瑞迪官网',
    url: 'http://www.readyart.cn/'
  }
]

const list_data2 = [
  {
    img: jzsq,
    name: '中小学家长社区',
    url: 'https://www.zhongxiaoxue.cn/'
  },
  {
    img: ydsx,
    name: '一点升学网',
    url: 'https://www.yidianedu.com/'
  }
]

const list_data3 = [
  {
    img: ddbb,
    name: '点点报班',
    code: ddbb_code
  },
  {
    img: sdb,
    name: '四点半学堂',
    url: 'http://sidianbanh5.yanuojiaoyu.com'
  },
  {
    img: lds,
    name: '绿地社',
    url: 'http://www.lvdishe.com/'
  }
]

const list_data4 = [
  {
    img: dd,
    name: '钉钉',
    url: 'https://www.dingtalk.com/'
  },
  {
    img: qw,
    name: '企业微信',
    url: 'https://work.weixin.qq.com/'
  },
  {
    img: ec,
    name: 'EC',
    url: 'https://www.scrm.com/'
  },
  {
    img: xgj,
    name: '校管家-亚诺',
    url: 'https://tms10.xiaogj.com/index.html'
  },
  {
    img: xgj,
    name: '校管家-瑞迪',
    url: 'https://tms11.xiaogj.com/login.aspx'
  },
  {
    img: xet,
    name: '小鹅通',
    url: 'https://www.xiaoe-tech.com/'
  },
  {
    img: yt,
    name: '鱼塘软件',
    url: 'https://www.dzsaas.cn/'
  }
]

const Platform_center = () => {

  const [height, heightSet] = useState()

  useEffect(() => {
    handleSize()
    window.addEventListener('resize', handleSize);
    get_employee_listFun()
    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  const get_employee_listFun = () => {
    get_employee_list().then(res => {
      if (res.code === 0) {

      }
    })
  }

  const handleSize = () => {
    let height = document.body.clientHeight
    heightSet(height)
  }

  let list = [
    {
      title: '亚诺/瑞迪C端',
      list: list_data1
    },
    {
      title: '内容产品端',
      list: list_data2
    },
    {
      title: '赋能B端',
      list: list_data3
    },
    {
      title: '第三方平台',
      list: list_data4
    }
  ]

  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: height - 160 + 'px' }}>
      {
        list.map((item, index) => (
          <div key={index}>
            <Divider orientation="left">{item.title}</Divider>
            <Row gutter={[8, 24]}>
              {
                item.list.map((item, index) => (
                  <Col key={index} xs={12} sm={12} md={8} lg={8} xl={6} xxl={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    {
                      item.url ?
                        <img alt='' onClick={e => { if (item.url) { window.open(item.url) } }} style={{ width: '80px', height: '80px', cursor: 'pointer' }} src={item.img} />
                        :
                        <Popover content={<img alt='' style={{ width: '150px', height: '150px' }} src={item.code} />}>
                          <img alt='' onClick={e => { if (item.url) { window.open(item.url) } }} style={{ width: '80px', height: '80px' }} src={item.img} />
                        </Popover>
                    }
                    <div style={{ marginTop: '10px', fontSize: '16px' }}>
                      {item.name}
                    </div>
                  </Col>
                ))
              }
            </Row>
          </div>

        ))
      }
    </div>
  )
}

export default Platform_center;