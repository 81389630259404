import React, { useEffect, useState } from 'react';
import { Table, Input, Select, Button } from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from '@ant-design/icons';
import { get_union_user_list } from "../../server/https";
import { useHistory } from 'react-router-dom';
import { changeTreeDate } from "../../common/changeTreeDate";

const { Option } = Select

const paramsModel = {
  nickname: '',
  name: '',
  mobile: '',
  is_student: '',
  is_ec: '',
  is_taizhang: '',
  follow_count: '',
  page: 1,
  page_size: 10
}

const Mp_list = () => {

  let history = useHistory()
  const [params, paramsSet] = useState(paramsModel)
  const [dataSource, dataSourceSet] = useState([])
  const [total, totalSet] = useState(0)

  useEffect(() => {
    get_union_user_listFun()
  }, [params.page, params.page_size])

  const get_union_user_listFun = () => {
    let data = params
    get_union_user_list(data).then(res => {
      if (res.code === 0) {
        let list = changeTreeDate(res.data.list, 'key', 'id')
        dataSourceSet(list)
        totalSet(res.data.count)
      }
    })
  }

  const tableChange = (pagination) => {
    let data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data.page_size = pagination.pageSize
    data.page = pagination.current
    paramsSet(data)
  }

  const inputChange = (e, id) => {
    let value = typeof (e) === 'undefined' ? '' : typeof (e) === 'object' ? e.target.value : e,
      data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data[id] = value
    paramsSet(data)
  }

  const columns = [
    {
      title: '头像',
      dataIndex: 'headimgurl',
      key: 'headimgurl',
      render: (text) => (
        <img src={text} alt="" style={{ width: 40, height: 40 }} />
      )
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (text, record) => (
        <div style={{ color: 'rgb(17, 136, 255)', cursor: 'pointer' }} onClick={() => { history.push('/mp_list/customer_information/' + record.unionid) }}>{text}</div>
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '关注公众号',
      dataIndex: 'mp_str',
      key: 'mp_str',
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'EC',
      dataIndex: 'is_ec',
      key: 'is_ec',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '1' ?
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              :
              <CloseCircleTwoTone twoToneColor="#eb2f96" />
          }
        </div>
      )
    },
    {
      title: '学员',
      dataIndex: 'is_student',
      key: 'is_student',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '1' ?
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              :
              <CloseCircleTwoTone twoToneColor="#eb2f96" />
          }
        </div>
      )
    },
    {
      title: '上门',
      dataIndex: 'is_taizhang',
      key: 'is_taizhang',
      align: 'center',
      render: (text, record) => (
        <div>
          {
            text === '1' ?
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              :
              <CloseCircleTwoTone twoToneColor="#eb2f96" />
          }
        </div>
      )
    },
    {
      title: '关注时间',
      dataIndex: 'add_time',
      key: 'add_time',
    }
  ]

  return (
    <div style={{ height: '100%' }}>
      <div className='search_box'>
        昵称：
        <Input placeholder="请输入" onChange={e => inputChange(e, 'nickname')} allowClear />
        公众号名称：
        <Input placeholder="请输入" onChange={e => inputChange(e, 'mp_name')} allowClear />
        姓名：
        <Input placeholder="请输入" onChange={e => inputChange(e, 'name')} allowClear />
        手机号：
        <Input placeholder="请输入" onChange={e => inputChange(e, 'mobile')} allowClear />
        是否老学员：
        <Select placeholder="请选择" onChange={e => inputChange(e, 'is_student')} allowClear>
          <Option value="1">是</Option>
          <Option value="-1">否</Option>
        </Select>
        是否是EC用户：
        <Select placeholder="请选择" onChange={e => inputChange(e, 'is_ec')} allowClear>
          <Option value="1">是</Option>
          <Option value="-1">否</Option>
        </Select>
        是否上过门：
        <Select placeholder="请选择" onChange={e => inputChange(e, 'is_taizhang')} allowClear>
          <Option value="1">是</Option>
          <Option value="-1">否</Option>
        </Select>
        关注数量（大于）：
        <Input placeholder="请输入" onChange={e => inputChange(e, 'follow_count')} allowClear />
        <Button onClick={e => {
          if (params.page === 1)
            get_union_user_listFun()
          else {
            let data1 = JSON.stringify(params),
              data = JSON.parse(data1)
            data.page = 1
            paramsSet(data)
          }
        }} style={{ marginRight: '10px' }}>搜索</Button>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: params.page,
          total: total,
          pageSize: params.page_size
        }}
        onChange={e => tableChange(e)}
      />
    </div>
  )
}

export default Mp_list;