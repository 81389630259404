import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Modal, Input, Select, message, Form, Popconfirm, Tree } from 'antd';
import { get_employee_list, get_department_list, add_employee, del_employee, edit_employee, get_company_list } from "../../server/https";
import { changeTreeDate } from "../../common/changeTreeDate";
import myContext from '../../common/createContext'

const { Option, OptGroup } = Select;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 12,
  },
}
const params_mode = {
  name: '',
  username: '',
  permission: '',
  state: '',
  personal_mobile: '',
  department_id: '',
  is_del: '',
  page: 1,
  page_size: 10,
}

const AddModal = (props) => {
  const [department_list, department_listSet] = useState([])
  const [company_list, company_listSet] = useState([])
  const value = useContext(myContext);
  const [form] = Form.useForm();

  useEffect(() => {
    get_department_listFun()
    get_company_listFun()
  }, [])

  const get_department_listFun = e => {
    get_department_list().then(res => {
      if (res.code === 0) {
        department_listSet(res.data.list)
      }
    })
  }

  const get_company_listFun = e => {
    get_company_list().then(res => {
      if (res.code === 0) {
        company_listSet(res.data.list)
      }
    })
  }

  useEffect(() => {
    if (value.visible && department_list.length > 0) {
      form.setFieldsValue(value.editDate)
    }
  }, [value, department_list])// eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = e => {
    form.validateFields().then((values) => {
      if (JSON.stringify(value.editDate) === '{}') {
        let data = values
        add_employee(data).then(res => {
          if (res.code === 0) {
            message.success('添加成功！')
            props.visibleChange();
            props.get_employee_listFun();
            form.resetFields();
          } else {
            message.warn(res.message)
          }
        })
      } else {
        let data = values
        data.employee_id = value.editDate.key
        edit_employee(data).then(res => {
          if (res.code === 0) {
            message.success('修改成功！')
            props.visibleChange();
            props.get_employee_listFun();
            form.resetFields();
          } else {
            message.warn(res.message)
          }
        })
      }
    }).catch(() => {
      message.warn('提交失败，请检查表格！')
    });
  }

  return (
    <Modal
      title={JSON.stringify(value.editDate) === '{}' ? "添加员工" : "修改员工"}
      visible={value.visible}
      onOk={handleOk}
      onCancel={() => { form.resetFields(); props.visibleChange(); }}
      width={800}
    >
      <Form
        form={form}
        {...layout}
        name="添加员工"
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Input allowClear placeholder="请输入" />
        </Form.Item>

        {
          JSON.stringify(value.editDate) === '{}' ?
            <Form.Item
              label="登录密码"
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入',
                },
              ]}
            >
              <Input allowClear placeholder="请输入" />
            </Form.Item>
            : ''
        }

        <Form.Item
          label="真实姓名"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Input allowClear placeholder="请输入" />
        </Form.Item>

        <Form.Item
          label="个人手机号"
          name="personal_mobile"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Input allowClear placeholder="请输入" />
        </Form.Item>

        <Form.Item
          label="工作手机号"
          name="work_mobile"
          rules={[
            {
              required: false,
              message: '请输入',
            },
          ]}
        >
          <Input allowClear placeholder="请输入" />
        </Form.Item>

        <Form.Item
          label="权限"
          name="permission"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value="1">系统管理员</Option>
            <Option value="2">管理员</Option>
            <Option value="3">校长</Option>
            <Option value="4">主管</Option>
            <Option value="5">普通员工</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="状态"
          name="state"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value="-1">离职</Option>
            <Option value="1">正常</Option>
            <Option value="2">冻结</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="职级"
          name="level"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value="1">专员</Option>
            <Option value="2">组长</Option>
            <Option value="3">主管</Option>
            <Option value="6">总监</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="职位"
          name="position"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Input allowClear placeholder="请输入" />
        </Form.Item>

        <Form.Item
          label="职位类型"
          name="postion_type"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value="1">全职</Option>
            <Option value="2">兼职</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="是否是教师岗"
          name="is_teaching"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value="1">是</Option>
            <Option value="-1">否</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="公司"
          name="company_id"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            {
              company_list.map((item, index) => (<Option key={index} value={item.company_id}>{item.company}</Option>))
            }
          </Select>
        </Form.Item>


        <Form.Item
          label="部门"
          name="department_id"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            {
              department_list.map(item => (
                item.children ?
                  <OptGroup key={item.id} label={item.name}>
                    {
                      item.children.map(item => (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                      ))
                    }
                  </OptGroup>
                  :
                  <Option key={item.id} value={item.id}>{item.name}</Option>
              ))
            }
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  );
};

const Staff_management = () => {
  let history = useHistory()
  const [gData, gDataSet] = useState([])
  const [params, paramsSet] = useState(params_mode)
  const [dataSource, dataSourceSet] = useState([])
  const [info, infoSet] = useState()
  const [total, totalSet] = useState(0)
  const [visible, visibleSet] = useState(false)
  const [editDate, editDateSet] = useState({})
  const [height, heightSet] = useState()

  const get_department_listFun = e => {
    get_department_list().then(res => {
      if (res.code === 0) {
        let newlist = changeTreeDate(res.data.list, 'title', 'name')
        newlist = changeTreeDate(newlist, 'key', 'id')
        gDataSet(newlist)
      }
    })
  }

  useEffect(() => {
    get_employee_listFun()
  }, [params.page, params.page_size, info])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSize()
    get_department_listFun()
    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  const handleSize = () => {
    let height = document.body.clientHeight
    heightSet(height)
  }

  const get_employee_listFun = () => {
    let data = params
    get_employee_list(data).then(res => {
      if (res.code === 0) {
        let list = changeTreeDate(res.data.list, 'key', 'id')
        dataSourceSet(list)
        totalSet(res.data.total_count)
      }
    })
  }

  const tableChange = (pagination) => {
    let data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data.page_size = pagination.pageSize
    data.page = pagination.current
    paramsSet(data)
  }

  const inputChange = (e, id) => {
    let value = typeof (e) === 'undefined' ? '' : typeof (e) === 'object' ? e.target.value : e,
      data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data[id] = value
    paramsSet(data)
  }

  const visibleChange = e => {
    let t = !visible
    if (visible) {
      editDateSet({})
    }
    visibleSet(t)
  }

  const confirm = id => {
    let data = { employee_id: id }
    del_employee(data).then(res => {
      if (res.code === 0) {
        message.success('删除成功！')
        get_employee_listFun()
      } else {
        message.warn(res.message)
      }
    })
  }

  const editChange = record => {
    editDateSet(record)
    visibleChange()
  }

  const onDragEnter = info => {
    let data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data['department_id'] = info
    paramsSet(data)
    infoSet(info)
  }

  const onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...gData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    gDataSet(data)
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div onClick={() => { history.push('/personnelmanagement/staff_management_detail/' + record.key) }} style={{ color: '#18f', cursor: 'pointer' }}>{text}</div>
      )
    },
    {
      title: '个人手机号',
      dataIndex: 'personal_mobile',
      key: 'personal_mobile',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <div>
          <Button onClick={e => editChange(record)}>修改</Button>
          <Popconfirm
            title="是否确认离职？"
            onConfirm={() => confirm(record.key)}
            okText="是"
            cancelText="否"
          >
            <Button style={{ marginLeft: '10px' }}>离职</Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  return (
    <div>
      <div className='flex_box'>
        <div className='left_box' style={{ maxHeight: height - 222 + 'px' }}>
          <Tree
            className="draggable-tree"
            blockNode
            onSelect={e => onDragEnter(e)}
            onDrop={e => onDrop()}
            showLine={true}
            treeData={gData}
          />
        </div>
        <div className='right_box' style={{ maxHeight: height - 222 + 'px' }}>
          <div className='search_box'>
            名称：<Input placeholder="请输入" onChange={e => inputChange(e, 'name')} allowClear />
            用户名：<Input placeholder="请输入" onChange={e => inputChange(e, 'username')} allowClear />
            权限：<Select placeholder="请选择" onChange={e => inputChange(e, 'permission')} allowClear>
              <Option value="1">系统管理员</Option>
              <Option value="2">管理员</Option>
              <Option value="3">校长</Option>
              <Option value="4">主管</Option>
              <Option value="5">普通员工</Option>
            </Select>
            状态：<Select placeholder="请选择" onChange={e => inputChange(e, 'state')} allowClear>
              <Option value="1">正常</Option>
              <Option value="2">冻结</Option>
            </Select>
            个人手机号：<Input placeholder="请输入" onChange={e => inputChange(e, 'personal_mobile')} allowClear />
            是否离职：<Select placeholder="请选择" onChange={e => inputChange(e, 'is_del')} allowClear>
              <Option value="-1">否</Option>
              <Option value="1">是</Option>
            </Select>
            <Button onClick={e => get_employee_listFun()} style={{ marginRight: '10px' }}>搜索</Button>
            <Button onClick={e => visibleChange()}>添加</Button>
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{
              current: params.page,
              total: total,
              pageSize: params.page_size
            }}
            onChange={e => tableChange(e)}
          />
        </div>
      </div>
      <myContext.Provider value={{ visible: visible, editDate: editDate }}  >
        <AddModal visibleChange={visibleChange} get_employee_listFun={get_employee_listFun} />
      </myContext.Provider>
    </div >
  )
}

export default Staff_management;