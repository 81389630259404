import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Divider, Table, Descriptions, Tag, Form, Input, Modal, message } from 'antd';
import {
  EditOutlined
} from '@ant-design/icons';
import { get_union_user_detail, edit_union_user } from "../../server/https";
import { changeTreeDate } from "../../common/changeTreeDate";
import myContext from '../../common/createContext'
import moment from "moment";

const { TabPane } = Tabs;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 12,
  },
}

const Active_user_list = () => {
  const value = useContext(myContext);

  useEffect(() => {
  }, [])

  const columns = [
    {
      title: '活动编号',
      dataIndex: 'active_id',
      key: 'active_id',
    },
    {
      title: '活动名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '查看时间',
      dataIndex: 'add_time',
      key: 'add_time',
      sorter: (a, b) => moment(a.add_time).unix() - moment(b.add_time).unix(),
      defaultSortOrder: 'descend'
    },
    {
      title: '是否提交',
      dataIndex: 'is_submit',
      key: 'is_submit',
      render: (text) => (
        <div>{text == '-1' ? '未提交' : '已提交'}</div>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={value.active_user_list}
        columns={columns}
      />
    </>
  )
}

const Record_list = () => {
  const value = useContext(myContext);

  useEffect(() => {
  }, [])

  const columns = [
    {
      title: '查看时间',
      dataIndex: 'add_time',
      width: 200,
      sorter: (a, b) => moment(a.add_time).unix() - moment(b.add_time).unix(),
      defaultSortOrder: 'descend'
    },
    {
      title: '操作内容',
      dataIndex: 'log'
    },
    {
      title: '地址',
      dataIndex: 'url',
      render: (text) => (
        <a href={text}>{text}</a>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={value.record_list}
        columns={columns}
      />
    </>
  )
}

const Customer_information = () => {

  let history = useHistory()
  const [form] = Form.useForm();
  const [unionid, unionidSet] = useState()
  const [active_user_list, active_user_listSet] = useState([])
  const [model, modelSet] = useState({})
  const [record_list, record_listSet] = useState([])
  const [student_model, student_modelSet] = useState({})
  const [visible, visibleSet] = useState(false)

  useEffect(() => {
    let unionid = history.location.pathname.split('/')[3]
    unionidSet(unionid)
  }, [])

  useEffect(() => {
    if (unionid) {
      get_union_user_detailFun()
    }
  }, [unionid])

  const get_union_user_detailFun = e => {
    let data = {
      unionid: unionid
    }
    get_union_user_detail(data).then(res => {
      if (res.code === 0) {
        active_user_listSet(res.data.active_user_list)
        modelSet(res.data.model)
        record_listSet(res.data.record_list)
        student_modelSet(res.data.student_model)
      }
    })
  }

  const handleOk = e => {
    form.validateFields().then((values) => {
      let data = values
      data.unionid = unionid
      edit_union_user(data).then(res => {
        if (res.code === 0) {
          message.success('修改成功！')
          get_union_user_detailFun()
          form.resetFields()
          visibleSet(false)
        } else {
          message.err(res.message)
        }
      })
    }).catch((err) => {
      message.warn('修改失败，请检查表单！')
    });
  }

  return (
    <div>
      <div>
        <Divider orientation="left">用户详情<EditOutlined style={{ color: 'rgb(17, 136, 255)' }} onClick={e => {form.setFieldsValue(model);visibleSet(true);}} /></Divider>
        <Descriptions title="" layout="vertical" bordered>
          <Descriptions.Item label="头像"><img src={model.headimgurl} alt="" style={{ width: 40, height: 40 }} /></Descriptions.Item>
          <Descriptions.Item label="姓名">{model.name ? model.name : '-'}</Descriptions.Item>
          <Descriptions.Item label="昵称">{model.nickname ? model.nickname : '-'}</Descriptions.Item>
          <Descriptions.Item label="手机号">{model.mobile ? model.mobile : '-'}</Descriptions.Item>
          <Descriptions.Item label="关注公众号列表">
            {
              model.mp_str ?
                model.mp_str.split(',').map(item => (
                  <Tag>{item}</Tag>
                ))
                :
                ''
            }
          </Descriptions.Item>
        </Descriptions>
        {
          student_model ?
            <>
              <Divider orientation="left">学生详情</Divider>
              <Descriptions title="">
                <Descriptions.Item label="姓名">{student_model.name}</Descriptions.Item>
                <Descriptions.Item label="学管">{student_model.master}</Descriptions.Item>
                <Descriptions.Item label="年级">{student_model.fulltime_grade}</Descriptions.Item>
                <Descriptions.Item label="班级">{student_model.fulltime_class}</Descriptions.Item>
                <Descriptions.Item label="课程列表">{student_model.current_class_str}</Descriptions.Item>
              </Descriptions>
            </>
            :
            ''
        }
      </div>
      <Tabs defaultActiveKey="1" type="card" style={{ marginTop: '40px' }}>
        <TabPane tab="活动记录" key="1">
          <myContext.Provider value={{ active_user_list: active_user_list }}>
            <Active_user_list />
          </myContext.Provider>
        </TabPane>
        <TabPane tab="行为轨迹" key="2">
          <myContext.Provider value={{ record_list: record_list }}>
            <Record_list />
          </myContext.Provider>
        </TabPane>
      </Tabs>
      <Modal
        title="编辑信息"
        visible={visible}
        onOk={handleOk}
        onCancel={() => { form.resetFields(); visibleSet(false); }}
        width={800}
      >
        <Form
          form={form}
          {...layout}
          name="添加员工"
        >
          <Form.Item
            label="姓名"
            name="name"
            rules={[
              {
                required: true,
                message: '请输入',
              },
            ]}
          >
            <Input placeholder="请输入" allowClear />
          </Form.Item>

          <Form.Item
            label="手机号"
            name="mobile"
            rules={[
              {
                required: true,
                message: '请输入',
              },
            ]}
          >
            <Input type='number' placeholder="请输入" allowClear />
          </Form.Item>

        </Form>
      </Modal>
    </div>
  )
}

export default Customer_information;