import React, { useState } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import { Layout, Menu, Avatar, Dropdown, Modal, Form, Input, message } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, ReadOutlined, ToolOutlined, HomeOutlined, EditTwoTone, LogoutOutlined, SnippetsOutlined } from '@ant-design/icons';
import { logout, change_password_byself } from "../server/https";
import platform_center from "./platform_center";
import mail_list from "./mail_list";
import personnel_management from "./personnel_management";
import mp_list from "./wxmp/mp_list";
import staff_management_detail from './personnel/staff_management_detail'
import customer_information from './personnel/customer_information'

const { Header, Sider, Content } = Layout;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 12,
  },
};

const Index = (props) => {
  const [collapsed, collapsedSet] = useState(false)
  const [visible, visibleSet] = useState(false)

  const [form] = Form.useForm();

  const toggle = () => {
    let f = !collapsed
    collapsedSet(f)
  };

  const homepage = e => {
    props.history.push('/')
  };

  const handleOk = e => {
    form.validateFields().then((values) => {
      let data = values
      change_password_byself(data).then(res => {
        if (res.code === 0) {
          message.success('修改成功！')
          visibleSet(false);
          form.resetFields();
        } else {
          message.error(res.message)
        }
      })
    })
  };

  const logOutFun = e => {
    let data = {}
    logout(data).then(res => {
      if (res.code === 0) {
        localStorage.removeItem('token')
        window.location.href = '/#/login';
      }
    })
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={homepage}>
        <HomeOutlined />首页
        </Menu.Item>
      <Menu.Item onClick={e => visibleSet(true)}>
        <EditTwoTone />修改密码
        </Menu.Item>
      <Menu.Item onClick={logOutFun}>
        <LogoutOutlined twoToneColor='#f40' />退出登录
        </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logoicon" onClick={e => homepage()}></div>
        <Menu theme="dark" mode="inline">
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link to="/">平台中心</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<ReadOutlined />}>
            <Link to="/maillist">通讯录</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ToolOutlined />}>
            <Link to="/personnelmanagement">组织管理</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<SnippetsOutlined />}>
            <Link to="/mp_list">公众号粉丝管理</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: e => toggle(),
          })}
          <div className="m-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: 50, position: 'absolute', top: '0', right: '0' }}>
            <Avatar size="large" style={{ marginRight: '10px' }} icon={<UserOutlined />} />
            <Dropdown overlay={menu}>
              <div>
                {localStorage.getItem('username')}
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            overflow: 'auto'
          }}
        >
          <Switch>
            <Route path='/' exact component={platform_center} />
            <Route path='/maillist' exact component={mail_list} />
            <Route path='/personnelmanagement' exact component={personnel_management} />
            <Route path='/personnelmanagement/staff_management_detail/:id' exact component={staff_management_detail} />
            <Route path='/mp_list' exact component={mp_list} />
            <Route path='/mp_list/customer_information/:id' exact component={customer_information} />
          </Switch>
        </Content>
      </Layout>
      <Modal
        title="修改密码"
        visible={visible}
        onOk={handleOk}
        onCancel={() => { form.resetFields(); visibleSet(false); }}
        width={800}
      >
        <Form
          form={form}
          {...layout}
          name="添加员工"
        >

          <Form.Item
            label="旧密码"
            name="old_password"
            rules={[
              {
                required: true,
                message: '请输入',
              },
            ]}
          >
            <Input.Password allowClear placeholder="请输入" />
          </Form.Item>

          <Form.Item
            label="新密码"
            name="new_password"
            rules={[
              {
                required: true,
                message: '请输入',
              },
            ]}
          >
            <Input.Password allowClear placeholder="请输入" />
          </Form.Item>

        </Form>
      </Modal>
    </Layout>
  )
};

export default withRouter(Index);